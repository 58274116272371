import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function CopyrightRegistration() {
  /* Slider */
  // const copySlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Online Copyright Registration`,
  //     content: `Secure Copyright Registration for Your Products and Brands`,
  //     image: "/imgs/registration/ipr/td6.png",
  //     alt_tag: "Best Online Trademark Renewal Service Near Bangalore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Copyright Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `Free Copyright
        Search Report`,
        icon: "/imgs/icons/bregistration/audit.png",
        alt_tag: "Copyright Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `10% discount on 4+ 
          applications`,
        icon: "/imgs/icons/it/80u.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const copyAboutData = {
    header: `Online Copyright Registration`,
    sub_title: `Online Copyright filing for your business and organizations through TODAYFILINGS experts.`,
    content_paragraph: [
      `Copyright is a legal prohibition against copying someone else's work. The exclusive right to the owner's work 
      or creation is provided by copyright. By securing a valid copyright license for their works and efforts, 
      authors of "literary," "musical," "dramatic," and "artistic" works as well as "producers" of films, videos, 
      and music recordings can also register the rights to their original creations.`,

      // `By preventing unauthorized use of the original work, copyright filings preserve and
      // honor creativity. `,
    ],
  };

  /* Scroll Nav Data */
  const copyScrollId = [
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#benefitss`,
      heading: `Benefits`,
    },
    {
      id: `#types`,
      heading: `Types`,
    },
  ];

  /* Img Content Component Data */
  const copyIcData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Copyright Registration Required Documents',
    image: '/imgs/registration/ipr/td7.png',
    alt_tag: 'Copyright Registration',
    points: [
      {
        head: 'Personal details about the applicant',
        content: `Name, residence, and country of the applicant,Author's name, address, 
        and country of origin,Duplicates of the author's first creation.`,
        icon: true,
      },
      {
        head: '',
        content: `The nature of the applicant's copyright issue is whether the applicant's representation 
        is the actual author work.`,
        icon: true,
      },
      {
        head: '',
        content: `When it relates to business operations, provide registration documentation and author identification.`,
        icon: true,
      },
      {
        head: 'The Type Of Work',
        content: `Creative job classification and description,The creative work's name,Language used in the Original Work`,
        icon: true,
      },
      {
        head: '',
        content: `Date of Release - The publication, such as internal magazines, in which the work is submitted.`,
        icon: true,
      },
      {
        head: '',
        content: `A research paper handed in to a professor or a company magazine are not regarded as publications.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const copyCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'The Process For Registering A Copyright In India',
    content: ``,
    body: [
      {
        head: `Copyright Registration Procedure`,
        points: `The steps involved in the copyright registration process are as follows....`,
        icon: true,
      },
      {
        head: ``,
        points: `Step 1: The copyright application form must be submitted to the relevant Copyright Registrar 
        together with the necessary information about the work. You can submit different copyright applications 
        depending on the nature of work.`,
        icon: true,
      },
      {
        head: ``,
        points: `Step 2: Following the applicant's correct signature on the application documents, the finished 
        application and any necessary supplemental materials must be electronically submitted to the Registrar of 
        Copyrights Online.`,
        icon: true,
      },
      {
        head: ``,
        points: `Step 3: The Diary number is given out when the application has been submitted online.`,
        icon: true,
      },
      {
        head: ``,
        points: `Step 4: The Copyright Examiner then evaluates the filed application for any errors or 
        objections within 30 days.`,
        icon: true,
      },
      {
        head: ``,
        points: `Step 5: If there are any discrepancies or objections, a discrepancy notice will be issued,
        and the same must be complied with within 30 days of the notification's issuance.`,
        icon: true,
      },
      {
        head: ``,
        points: `Step 6: The copyright must be included after the dispute has been resolved or if the application 
        is approved. The Copyright Office will then issue the Extracts of Register of Copyrights (ROC), which is the 
        Registration Certificate of Copyright.`,
        icon: true,
      },
      {
        head: `Before the Rejection`,
        points: `According to Section 25(1) of the Patent (Amendment) Act 2005, anyone can file a dispute.`,
        icon: true,
      },
      {
        head: ``,
        points: `Previously communicated`,
        icon: true,
      },
      {
        head: ``,
        points: `Non-patentability`,
        icon: true,
      },
      {
        head: ``,
        points: `Accuracy and sufficiency of description`,
        icon: true,
      },
      {
        head: ``,
        points: `holding the priority incorrectly`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const copyCiData = {
    id: 'types',
    background: 'main-content',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Six Types Of Copyright Registers',
    paragraph: ``,
    points: [
      {
        content: `Category 1: Literature not included in computer programmes.`,
        icon: true,
      },
      {
        content: `Category 2: Music-related works.`,
        icon: true,
      },
      {
        content: `Category 3: Creative works or creations.`,
        icon: true,
      },
      {
        content: `Category 4: Films or videos that use cinematography.`,
        icon: true,
      },
      {
        content: `Category 5: Sound capture.`,
        icon: true,
      },
      {
        content: `Category 6: Tables, programmes, and compilations for computers.`,
        icon: true,
      },
      {
        head: 'Patent Inspection',
        content: `The process of registering a patent includes a crucial stage called patent 
        examination. The patent examination comes next after the request is published. Since the 
        process is not automated, the patent registration application must request inspection or patent 
        examination within 48 months after the priority date or the registration date of the request, 
        whichever occurs first.`,
        icon: true,
      },
      {
        head: 'Authorize',
        content: `If all the necessary documentation are submitted and the patent is in compliance with the law, 
        it will be granted. The patent registration is good for 20 years.`,
        icon: true,
      },
    ],
    image: '/imgs/business/form-filing.png',
    alt_tag: 'Required Documents for Copyright Registration',
  };

  /* ImgSlider Data */
  const copyImages = [
    '/imgs/registration/ipr/td2.png',
    '/imgs/registration/ipr/td7.png',
    '/imgs/registration/ipr/td8.png',
  ];

  /*  Slider Content Component Data */
  const copySCData = {
    id: 'benefitss',
    background: [],
    mt_div: 'mt-3',
    header: 'Copyright Registration Advantages',
    content: [
      {
        points: `Artistic works,Brand worth`,
        icon: true,
      },
      {
        points: `Clear Evidence,Security owner`,
        icon: true,
      },
      {
        points: `Public Document,May report infringement`,
        icon: true,
      },
      {
        points: `Protection continued even after the Creator's passing,`,
        icon: true,
      },
      {
        points: `Copyright serves as a Resource.`,
        icon: true,
      },
    ],
    images: copyImages,
  };

  var FAQ_data = [
    {
      header: 'Does copyright protect names or titles?',
      body: [
        {
          content: `Generally speaking, copyright does not safeguard all forms of
          creative ideas or concepts, brief phrases, short word combinations,
          slogans, plots, methodologies, or factual facts. Only unique
          creative or literary works are accorded copyright.`,
          icon: false,
        },
      ],
    },

    {
      header:
        'Is there any protection if someone duplicates my original content after I file for a copyright?',
      body: [
        {
          content: `Surely, yes. You can send a legal notice to anyone who plagiarizes
          your original work, and if they fail to respond, you can even
          bring legal action against them.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'How can I find out whether someone else has copyrighted my work?',
      body: [
        {
          content: `No, there is no method to determine whether a third party has
          copyrighted my work.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can I sell or give away my copyright registration?',
      body: [
        {
          content: `A copyright registration may be offered for sale, given as a gift,
          given away, transferred, or franchised with the author's consent.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Are copyright laws still applicable for mobile apps?',
      body: [
        {
          content: `Although a mobile application cannot be protected as such, its
          coding can be legally protected by copyright as a literary work, and
          other parts of the programme can be registered under various
          copyright registration procedures, just like website content.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can titles or names be copyrighted?',
      body: [
        {
          content: `A copyright registration does not protect the titles or names, 
          short words, slogans, or any phrases. Only original literary work can get copyright registration.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What is the difference between Copyright registration and trademark registration?',
      body: [
        {
          content: `Trademark registration protects the brand name, logo the slogan whereas copyright protection is 
          provided to literary works, music, videos, slogans, and artistic contents.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How Long is the Copyright registration valid?',
      body: [
        {
          content: `Once obtained copyright registration has a validity of 60 years. In case it is literary, drama, 
          music work the 60-year validity is considered from the death of the author. But in the case of the films, 
          sound recording, photographs the 60 year validity period is considered from the date of publication.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Copyright obtained in India is only valid in India or across the world?',
      body: [
        {
          content: `According to the Berne Convention, copyright obtained in India will get the status of foreign 
          workers and the copyright protection is extended to the countries which are signatories to the Berne Convention.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it possible to sell or transfer Copyright registration?',
      body: [
        {
          content: `With the consent from the owner of the work, the copyright registration can be sold, transferred, gifted as well as franchised.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Copyright Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={copySlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={copyAboutData} />

          <Counter />
          <ScrollNav scroll_data={copyScrollId} />
          <ImgContent item={copyIcData} />
          <ContentForm CFSection_data={copyCfData} />
          <SliderContent ScSection_data={copySCData} />
          <ContentImg CISection_data={copyCiData} />

          <Cta />
          <Guidance />
          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
